import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const HeaderOptions: React.FC = () => {
  const { t } = useTranslation();
  const expression = "landing";

  return (
    <>
      <NavLink className="navbar-item" activeClassName='is-active' to='/book-a-stay'>
        {t(`${expression}.book`)}
      </NavLink>

      <NavLink className="navbar-item" activeClassName='is-active' to='/list-property'>
        {t(`${expression}.host`)}
      </NavLink>

      <NavLink className="navbar-item" activeClassName='is-active' to='/partner-with-us'>
        Partner with us
      </NavLink>

      <NavLink className="navbar-item" activeClassName='is-active' to='/locations'>
        {t(`${expression}.locations`)}
      </NavLink>

      <NavLink className="navbar-item" activeClassName='is-active' to='/our-services' >
        {t(`${expression}.services`)}
      </NavLink>

      <NavLink className="navbar-item" activeClassName='is-active' to='/offers' >
        {t(`${expression}.offer`)}
      </NavLink>
    </>
  );
};

export default HeaderOptions;
