const en = {
  landing: {
    offer: "Offers",
    services: "Services",
    locations: "Locations",
    host: "Be our Host",
    book: "Book a Stay",
    register: "Register / Login",
    switch: "Switch to",
    owner: "Owner",
    guest: "Guest",
  },
  search: {
    locations: "Locations",
    forStay: "I'm looking for a stay in",
    from: "From",
    checkIn: "Check-in",
    to: "To",
    checkOut: "Check-out",
    for: "For",
    guests: "Guests",
    title: "Search",
    find: "Find",
  },
  home: {
    title: {
      findYour: "FIND YOUR",
      perfPlace: "PERFECT PLACE",
      stayLong: "STAY AS LONG AS",
      youLike: "YOU LIKE",
    },
    banner: {
      exp: "Experience",
      dimension: "the new dimension of",
      comfort: "Comfort & Relaxation",
      withus: "with us.",
      sub1: "Take your pick from thousands of cool spaces, set the date,",
      sub2: "and we’ll make sure your new pad is Staycae-ready,",
      sub3: "ahead of your arrival.",
      button: "BOOK NOW",
    },
    homeOffer: {
      title: "Embrace the grandeur at your budget",
      deal: "Exciting deals with special offers",
      viewAll: "View All",
      details: "VIEW DETAILS",
    },
    attractions: {
      title: "Find your perfect stay to discover and relish...",
      subtitle: "Wondrous attractions around you",
      adventure: "Adventurous fun with your loved ones",
    },
    testimonials: {
      title: "Here's what our customers say",
      stayedAt: "Stayed at",
    },
  },
  footer: {
    contact: {
      phone: "Phone Number",
      mail: "Email",
      whatsapp: "WhatsApp",
    },
    about: {
      title: "ABOUT",
      whoarewe: "Who are we?",
      service: "Services",
      offers: "Offers",
    },
    help: {
      title: "HELP",
      contact: "Contact Us",
    },
    policies: {
      title: "POLICIES",
      cancellation: "Cancellation",
      payandRef: "Payment & Refund",
    },
    stayConnected: "STAY CONNECTED",
    stayUpdated: {
      title: "STAY UPDATED",
      enterMail: "Enter your email address",
      offer: "You will be able to receive notifications on recent offers.",
      emailPlaceholder: "Email Address",
      submit: "Submit",
    },
    copyRight: "STAYCAE UAE",
    copyRightText: "Copyright ©",
  },
  bookAStay: {
    booknow: "BOOK NOW",
    viewdetails: "VIEW DETAILS",
    subtitle: "Most recommended homes",
    rated: "Rated",
    title: "Featured Staycations",
    popularLocation: "Popular Locations",
  },
  searchResults: {
    filter: "Filter and Sort",
    reset: "Reset",
    price: "PRICE",
    discnt: "DISCOUNTS",
    rooms: "ROOMS",
    amnty: "AMENITIES",
    map: "MAP",
    list: "LIST",
    show: "Showing",
    prop: "properties",
    near: "Near",
    sort: "SORT",
    featured: "Featured",
    new: "Newest",
    dscnthl: "Discounts (High - Low)",
    pricehl: "Price (High - Low) ",
    pricelh: " Price (Low - High)",
    more: "View More",
    less: "Less",
    close: "Close",
    applyFilter: "APPLY FILTER",
  },
  stayDetails: {
    desc: "Description",
    loc: "Location",
    services: "Services",
    deals: "Deals",
    locs: "Locations",
    book: "Book",
    pricing: "Pricing",
    timing: "Timing",
    rules: "House Rules",
    attr: "Nearest Attractions",
    review: "Ratings & Reviews",
    avgrate: "Average Ratings",
    pcy: "Policies",
    stayHeader: "Other cosy homes you may like",
    reg: "Register",
    or: " or",
    login: "Login",
    priceInfo: "Click on the pricing you prefer.",
    rvw: "reviews",
    offerText: "get this stay for",
    save: "You save",
    in: "Check In",
    out: "Check Out",
    member: "Staycae Member",
    nonmember: "Non-Member",
    refund: "Refundable",
    nonrefund: "Non-refundable",
    ruleText: {
      groupAllowed: "Parties/events are allowed",
      groupNotAllowed: "Parties/events are not allowed",
      petsAllowed: "Pets allowed",
      petsNotAllowed: "Pets are not allowed",
      smokingAllowed: "Smoking allowed",
      smokingNotAllowed: "Smoking is not allowed",
      min_age_to_book: "Minimum age for check-in is",
    },
    propertyDist: "km(s) from the property",
  },
  common: {
    bedrooms: "Bedrooms",
    bathrooms: "Bathrooms",
    maxpersons: "Max Persons",
    bedroom: "Bedroom",
    attr: "Nearby Attractions",
    services: "Services",
    amnty: "Amenities",
    rated: "Rated",
    login: "LOGIN",
    email: "Email",
    pwd: "Password",
    fgtpwd: "Forgot Password?",
    submit: "Submit",
    profile: "Profile",
    logout: "Logout",
    pwdrequired: "Password is required",
    studio: "Studio",
    guests: "Guests",
    total: "Total",
    hosts: "Hosts",
    numberBath: "Number of Bathrooms",
    numberBed: "Number of Bedrooms",
    sqrfeet: "Square feet",
    invoice: "Invoice",
    transaction_id: "Transaction",
    date: "Date",
    atleastChar: "Password must contain 6-30 characters!",
    pwdChecks:
      "Password must contain at least a number, a lowercase letter, an uppercase letter, and a special character!",
    pwdMiss: "Those passwords didn’t match. Try again.",
  },
  notFound: {
    title: "SOMETHING IS WRONG ",
    paraText:
      " The page you are looking for was moved, removed, renamed or might never existed.",
    btn: " GO HOME",
  },
  login: {
    signup: "or sign up with",
    continue: "Continue as a Guest",
    noAccnt: "Dont have account?",
    reg: "Register Now",
  },
  register: {
    alreadyAccnt: "Already have an account?",
    gotologin: "Go to Login",
    firstname: "First Name",
    lastname: "Last Name",
    emailaddr: "Email Address",
    phone: "Phone",
    toastMsgs: {
      successfulReferral:
        "Registration successful using referral code. Before proceeding, please check your email for a verification link",
      expiredReferral:
        "Successfully registered but referral code expired. Before proceeding, please check your email for a verification link",
      defaultSignup:
        "Before proceeding, please check your email for a verification link",
    },
  },
  fgtpwd: {
    title: "Forgot Password",
    text: "Enter your email address below and we will send you instructions on how to change your password.",
  },
  header: {
    switch: "Switch to",
    guest: "Guest",
    owner: "Owner",
  },
  resetpwd: {
    title: "Reset Password",
    new: "New Password",
    retype: "Retype new password",
  },
  errmsg: {
    require: "is required",
    maximum: "should only have maximum of",
    char: "characters",
    minimum: "should contain atleast",
    limit: "should not exceed",
    atleast: " should be atleast ",
    invalid: "is invalid",
    phonerequire: "Phone Number is required",
    phoneinvalid: "Phone Number is invalid",
    human: "Please verify that you are a Human.",
  },
  aboutus: {
    wehave: "And Today We Have...",
    ourpartner: "Our Partners",
    partner: "PARTNERS",
    happyguest: "HAPPY GUESTS",
    host: "HOSTS",
  },
  contactus: {
    title: "CONTACT US",
    reach: "Reach out to us",
    locate: "Locate Us",
    question: "Do you have a question?  Leave us a messege",
    msg: "Message",
    verify: "Please verify that you are a Human.",
    send: "SEND",
    address1: "PO Box 119169",
    address2: "Marasi Drive, Business Bay",
    address3: "Dubai, UAE",
    contactno: "+971 4 210 0450",
  },
  Bathroom: "Bathroom",
  Internet: "Internet",
  Kitchen: "Kitchen",
  "Bed & Laundry": "Bed & Laundry",
  Entertainment: "Entertainment",
  "Other Facilities": "Other Facilities",
  "Service Charge": "Service Charge",
  VAT: "VAT",
  "Tourism Fee": "Tourism Fee",
  "Applied Coupon": "Applied Coupon",
  "Offer Discount": "Offer Discount",
  noUnits: {
    heading: "No units owned!",
    description: "Please navigate to our 'Be Our Host' page to get started",
    btnText: "GO TO DASHBOARD",
  },
  noReservation: "No reservation",
  noReservationDesc: "No reservations were made for the selected dates",
  noUnitsFound: "Report generation failed",
  noUnitsFoundDesc:
    "Could not generate report for selected date range/units. Please try with different dates/units",
  infoTxt: {
    text1:
      "Most of the places to stay are unavailable for your preferred dates on our site.",
    text2:
      "If you are flexible, try changing your dates to maximise availability or check the below available stays.",
    text3:
      "Most of the places to stay are unavailable for your dates/ search criteria.",
    text4:
      "If you are flexible, please revise your search or check the below available stays.",
    text5: "Sorry, all stays in",
    text6: "are sold out on Staycae.",
  },
  referral: {
    text1: "Invite your friends to Staycae and you earn a coupon with ",
    text12:
      "% off when they first book with us. You can earn a max of 5 coupons.",
    text2:
      "Share this referral link to your friends and family and earn coupons while they book with us.",
    text3: "(or)",
    text4: "Send invite through email",
    text5: "Please press",
    text6: "Enter",
    text7: "or",
    text8: "Space",
    text9: "after entering email address to add them to mailing list",
    text10: "invites left!",
    text11: "Send Invites",
    emailError: "Email entered is invalid",
    toastMsg: {
      success: "Referral invite sent!",
      error: "Error occurred while sending invite. Please try again later!",
      copy: "Copied coupon code!",
    },
  },
  location: {
    heading: "Spot the best place for your STAYCATION!",
    title: "Locations",
    subtitle1:
      "We search and list homes in the best locations in the city, so that your STAYCAE is not just fun but convenient too.",
    subtitle2:
      "You will be close to Malls, Public Transport, Restaurants and all the buzz, when you STAYCAE",
    button: "VIEW STAYS",
  },
  beHost: {
    title: "Be Our Host",
    desc: "Send us your property details and we will contact you in a week's time if it meets our criteria. We being an innovative property management solution turns your apartment into a hotel room, in turn fetching up to 30% higher ROI than long-term rentals.",
    subtitle: "Join us by renting your spare home",
    guarantee: "Privacy Guaranteed",
    knowBenefits: "Know Your Benefits With Staycae",
    propHeading: "HIGH RENTAL YIELD",
    propDesc: "Get more rent and stable cash flow",
    secureHeading: "VERIFIED GUESTS",
    secureDesc:
      "Tourists and Business Travelers are screened during the booking",
    freeHeading: "WORRY-FREE MAINTENANCE",
    freeDesc:
      "We handle all the maintenance so that your property retains value",
    timerHeading: "FLEXIBLE LEASE",
    timerDesc: "You can get back your property on shorter notice",
    personal: "Personal details",
    property: "Property details",
    submit: "Submit for Review",
    propName: "Property Name",
    proType: "Property Type",
    villa: "Villa",
    flat: "Flat",
    addr: "Address",
    sqrfeet: "Square feet",
  },
  villa: "Villa",
  flat: "Flat",
  Villa: "Villa",
  offers: {
    title: "Offers",
    subtitle:
      "Take your pick from thousands of cool spaces while taking advantage of our latest offers and deals. Get discounts on flash Deals, Seasonal offers and much more. We look forward to welcoming you in our homes.",
    stayMore: "Stay more save more",
    enjoy: "To enjoy the benefits",
    coupon: "(cheaper rates, special offers, and (coupons)",
    member: "of being a Staycae member",
    reg: "Register",
    login: "Login",
    monthlyDeal: "Available Deals for the Month",
    flashDeal: "Flash deals",
    seasonalOffer: "Seasonal offers",
    stayAndGet: "Stay and get offers",
    bankOffer: "Bank offers",
    hurry: "Hurry up!",
    valid: "Valid till",
    expire: "Expires in",
    view: "View Stays",
    new: "New Deals",
    fromTxt: "stay between",
    toText: "and",
    losTxt1: "Valid for",
    losTxt2: "or more nights stay",
    losTxt3: "or more nights",
  },
  payment: {
    title: "Payment",
    booking: "Booking Summary",
    cardNum: "Card number",
    exp: "Expiration date",
    cvc: "CVC",
    pay: "PAY NOW",
    timeRem: "Time Remaining to complete your booking",
    sorry: "Sorry your Payment window has expired",
    bookDetails: "Booking Details",
    adults: "Adults",
    summary: "Payment Summary",
    nights: "Nights",
    rooms: "Rooms",
    service: "Service Charge",
    disc: "Offer Discount",
    coupon: "Applied Coupon",
    vat: "VAT",
    tourismFee: "Tourism Fee",
    total: "Total(incl. VAT)",
    night: "Night",
    totalAmt: "Total:",
    youSave: "You save",
    applyCoupon: "Apply Coupon",
    success: "Your booking is successfully created.",
    bookingRef: "Booking Ref : SYC-",
    print: "To print your booking invoice",
    click: "Click Here",
    errorMsg: "Invoice cannot be generated for refundable bookings",
    dmgDeposit:
      "Damage deposit of AED 1000 to AED 5000 will be collected upon arrival by Cash/Card",
  },
  toast: {
    pwdinvalid: "Invalid Username or Password",
    resetsuccess: "Password reset successful!",
    internalerror: "An internal error occurred. Please try again later!",
    nomatchpwd: "Passwords entered don't match",
    invalidemail: "This email address is not available",
    forgetpwdmailsent:
      "We have sent a reset password link to your email account",
    usernotexist:
      "Couldn't find an account for this email. Enter a different account",
    userexist: "User with same email id already exists",
    signupproceed:
      "Before proceeding, please check your email for a verification link",
    contactsuccess:
      "Your message has been sent successfully. You can also contact us through social media, links can be found below!",
    resetlinkexpire: "Sorry your reset password link has expired",
    wrongoldpwd: "Wrong old password",
    alreadyuser: "You are already a Staycae subscriber.",
    general: "Something went wrong",
    profileupdate: "Profile updated successfully",
    verificationsent: "Verification email sent to your email account",
    invalidpassword: "Invalid password",
    verificationsuccess: "Email verification successful",
    oldnewpwdsame:
      "Your new password cannot be the same as your current password",
    nodetails: "Details for this room is currently unavailable",
    nocoupon: "Selected coupon is no longer available",
    unavailable:
      "Room is unavailable for the selected dates. Please adjust dates",
    verifyemail: "Email id is already verified",
    verifyphone: "Phone number has been verified successfully",
    sendotp: "We have sent a verification to your mobile number",
    failotp: "Failed to send verification code. Please try again later",
    expireotp: "Failed to authenticate. Expired OTP",
    wrongotp: "Wrong OTP entered. Please try again",
    nootp: "OTP has not been initiated for this email",
    limitexceed: "Resent limit exceeded. Please try after sometime",
    dateinvalid: "Selected dates are invalid",
    bookingfail:
      "Apologies, the selected unit is no longer available. Please try another date or unit.",
    propsubmit:
      "Your property details has been submitted. We'll get in touch with you soon",
    propexist: "List property with this Email already exists",
    success: "Action successfully completed",
    switchErr: "Please verify your email to switch to owner portal",
    payFail: "Sorry, Payment Failed! Please retry.",
    invalidCard: "Invalid card details.",
    verifyMail: "Please verify your email to login to owner portal",
    fileUpload: "Please upload file less than 2 mb",
    slug: "Invalid slug",
    couponAdded: "Coupon applied!",
    verifyEmail: "Verify your email before proceeding!",
    subscribed: "Successfuly Subscribed!",
    driveFileError: "Please try to upload a file from your device",
    driveImgError: "Please try to upload an image from your device",
    bookingUpdateError:
      "Booking didn't go through. Any money deducted will be credited back.",
    userExistCheckout:
      "This email address already exists. Please login with the same email address to complete your booking or provide a different email address.",
  },
  webCheckIn: {
    bannerHeading: "Guest Check-In",
    toastMsg: {
      INVALID_BOOKING: "Invalid booking",
      PRE_CHECK_IN_SUCCESSFUL:
        "Thank you for completing the pre-check-in details. Our team has been notified of your arrival. Please visit our reception desk upon your arrival to complete the check-in process and obtain your access to the unit.",
      USER_NON_ASSOCIATED_BOOKING:
        "This booking is not associated with this account. Please check your credentials and try again",
      PRE_CHECK_IN_ALREADY_COMPLETED:
        "Your pre-check-in has already been processed!",
      GENERAL_ERROR: "Something went wrong. Please check-in later",
      FILE_NAME_TOO_LONG: "File name cannot exceed 100 characters",
      EXPIRED_BOOKING: "This booking has expired",
      PRE_CHECK_IN_SUCCESSFUL_AND_VERIFICATION_MAIL_SENT:
        "Before proceeding, please check your email for a verification link",
    },
    additionalInfo: "Additional information on your arrival",
    additionalInfoPlaceholder:
      "Please specify you expected time of arrival, and any other requirements.",
    field: "This field",
    btnText: "Check-in",
    terms:
      "I understand that I will be required to fill and submit a health-declaration form upon my arrival",
    uaeUserText: "Please attach your Emirates ID",
    nonUaeUserText: "Please attach your passport copy or visa",
    errorMsg: "Please check this box to continue",
    noFile: "No file uploaded",
    chooseFile: "Choose a file",
    yes: "Yes",
    no: "No",
    residentLabel: "Are you a UAE resident?",
    imgRequired: "Please upload ID Proof",
    imgSizeError: "Please upload file less than 2 mb",
    cancellation:
      "You can check our cancellation policy here or reach out to us at",
    highlight: "here",
  },
  profile: {
    personal: "Personal Information",
    coupon: "Coupons",
    bookings: "Bookings",
    wishlist: "Wishlist",
    booking: "Booking",
  },
  bookings: {
    nobooking: "No Bookings Available!",
    past: "Past",
    coming: "Upcoming",
    active: "Active",
    cancelled: "Cancelled",
    departed: "Departed",
    arrived: "Arrived",
    extend: "EXTEND STAY",
    bookagain: "BOOK AGAIN",
    download: "View & Download Invoice",
    invoice: "Invoice",
    checkout: "Check-out Date",
    checkin: "Check-in Date",
    guests: "Guests",
    cancel: "CANCEL",
    bookedon: "Booked on",
  },
  personalInfo: {
    title: "Personal Information",
    verify: "Verify Now",
    verified: "Verified",
    edit: "Edit",
    cancel: "CANCEL",
    save: "Save",
    removePic: "Remove Image",
    country: "Country",
    city: "City",
    changepwd: "Change Password",
    currentpwd: "Current Password",
    confirmPwd: "Confirm Password",
  },
  wishlist: {
    nolist: "No Wishlist Available!",
    booknow: "Book Now",
  },
  coupons: {
    noCoupon: "No Coupons Available!",
    noCouponDesc:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diamnonumy eirmod",
    refer: "REFER AND EARN",
    or: "( or )",
    goHome: "GO HOME",
    disc: "Discount",
    get: "Get",
    offBook: "off on booking your favourite staycation home.",
    valid: "Valid until",
    terms: "TERMS & CONDITIONS",
    title: "Coupons",
    flat: "Flat Discount",
    couponNote:
      "Note: Coupons higher than the value of the booking cannot be applied.",
  },
  checkout: {
    highlight: "Hey, by being a Staycae Member book this stay at just",
    grab: "Grab this offer right away?",
    reg: "Register or Login",
    cancel: "No, Proceed as Guest",
    guestChk: "Guest Checkout",
    checkTypo:
      "Please check for typos. We would be sending the confirmation and invoice to the email address you provide.",
    proceed: "Proceed",
    verify: "Verify Phone Number",
    almostDone: "You are almost done!",
    otp: "OTP",
    otpExp: "OTP Expired",
    verifyText: "Verify",
    noOtp: "Didn't receive OTP?",
    resend: "Resend",
    wait: "Hey, Wait !!",
    waitDesc: "Are you sure you want to cancel this booking?",
    yes: "Yes",
    no: "No",
  },
  loyalty: {
    points: "POINTS",
    earned: "Points Earned:",
    error: "You are not enrolled to our loyalty program.",
  },
  resultCard: {
    others: "Others",
    showLess: "Show Less",
    forMem: "For Staycae Members",
    nonMem: "Non-Members",
  },
  guestExceed: {
    title: "Maximum occupants limit reached!",
    subtitle: "Guest count should not be greater than maximum persons",
    continue: "Continue Anyway",
    skip: "Skip for now",
  },
  addPhone: {
    title: "Add Mobile Number",
    description: "Add your mobile number to complete the booking process",
    save: "Save",
    cancel: "Skip now",
  },
  privacyPolicy: {
    bannerHeading: "Privacy Policy",
  },
};

export default en;
