import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/store";
import { DirectionCheck } from "../../../../utils/classnameSwitch";
import {
  clearState,
  resetPassword,
  resetPasswordSelector,
} from "features/reset-password/reset-password.slice";
import AuthLayout from "components/Layout/AuthLayout/AuthLayout";
import TextField from "components/Inputs/TextField";
import { checkPassword } from "utils/utility";
import { ResetPasswordProps } from "features/reset-password/ResetPassword";
import { setToastState } from "components/Toast/Toast.slice";
import useLocalStorage from "hooks/useLocalStorage";
import {
  setCurrentRoom,
  setPrice,
  setPriceSelected,
  setStayDetails,
} from "features/stay-details/stay-details.slice";
import { updateSelection } from "features/search-property/searchproperty.slice";

type Inputs = {
  verification_id: string;
  email: string;
  new_password: string;
  reenter_password: string;
};

const ResetPasswordComponent: React.FC<ResetPasswordProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {
    isFetching,
    isSuccess,
    isError,
    errorMessage,
    successMessage,
    errorType,
  } = useAppSelector(resetPasswordSelector);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  // eslint-disable-next-line
  const [resetPasswordFlow, setResetPasswordFlow] = useLocalStorage(
    "resetPasswordFlow",
    "false"
  );

  const validatePassword = (pwd: string) => {
    const { errorMsg, isValid } = checkPassword(pwd);
    setPasswordError(errorMsg);
    return isValid;
  };

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    data?.new_password === data?.reenter_password
      ? setPasswordMismatch(false)
      : setPasswordMismatch(true);
    data?.new_password === data?.reenter_password &&
      validatePassword(data.new_password) &&
      dispatch(
        resetPassword({
          ...data,
          email: props.email,
          verification_id: props.verification_id,
        })
      );
  };

  useEffect(() => {
    if (resetPasswordFlow === "true") {
      dispatch(
        setPriceSelected(JSON.parse(localStorage.getItem("priceSelected")!))
      );
      dispatch(setCurrentRoom(localStorage.getItem("currentRoom")));
      dispatch(setPrice(JSON.parse(localStorage.getItem("price")!)));
      const checkin = JSON.parse(localStorage.getItem("checkin")!);
      const checkout = JSON.parse(localStorage.getItem("checkout")!);
      const guests = JSON.parse(localStorage.getItem("guests")!);

      dispatch(
        updateSelection({
          category: "currentFrom",
          formattedD: checkin.formattedD,
          value: checkin.currentFrom,
          master: "checkin",
        })
      );
      dispatch(
        updateSelection({
          category: "currentTo",
          formattedD: checkout.formattedD,
          value: checkout.currentTo,
          master: "checkout",
        })
      );
      dispatch(
        updateSelection({
          category: "currentGuests",
          value: {
            adult: guests.currentGuests.adult,
          },
          master: "guests",
        })
      );
      dispatch(setStayDetails(JSON.parse(localStorage.getItem("stayDetail")!)));
    }
    // eslint-disable-next-line
  }, [resetPasswordFlow]);

  useEffect(() => {
    if (isError) {
      dispatch(
        setToastState({ type: "danger", message: errorMessage, dismiss: 3500 })
      );
      setTimeout(
        () =>
          errorType === "RESET_LINK_EXPIRED" &&
          history.push("/forgot-password"),
        3500
      );
      dispatch(clearState());
    }
    if (isSuccess) {
      dispatch(
        setToastState({
          type: "success",
          message: successMessage,
          dismiss: 3500,
        })
      );
      dispatch(clearState());
      history.push("/login");
    }
  }, [
    isError,
    isSuccess,
    dispatch,
    errorMessage,
    history,
    successMessage,
    errorType,
  ]);

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit(onSubmit)} dir={DirectionCheck()}>
        <span className="form-heading"> {t("resetpwd.title")}</span>
        <TextField
          label={t("common.email")}
          placeholder={t("common.email")}
          register={register}
          name="email"
          type="email"
          value={props.email}
          errors={errors}
          rules={{}}
          isReadOnly={true}
        />

        <TextField
          label={t("resetpwd.new")}
          placeholder={t("resetpwd.new")}
          register={register}
          name="new_password"
          type={newPasswordShown ? "text" : "password"}
          errors={errors}
          icon={newPasswordShown ? "show" : "hide"}
          rules={{
            validate: validatePassword,
          }}
          customError={t(passwordError)}
          passwordVisibibility={(status) => setNewPasswordShown(status)}
          className={passwordError === "common.pwdChecks" ? "errMsgGap" : ""}
        />

        <TextField
          label={t("resetpwd.retype")}
          placeholder={t("resetpwd.retype")}
          register={register}
          name="reenter_password"
          type={confirmPasswordShown ? "text" : "password"}
          errors={errors}
          icon={confirmPasswordShown ? "show" : "hide"}
          rules={{
            required: true,
            minLength: 6,
          }}
          customError={
            passwordMismatch ? "Those passwords didn’t match. Try again." : ""
          }
          passwordVisibibility={(status) => setConfirmPasswordShown(status)}
        />

        <div className="field is-grouped">
          <div className="control">
            <button
              className={`button is-link login-btn ${
                isFetching ? "is-loading" : ""
              }`}
              type="submit"
            >
              {t("common.submit")}
            </button>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
};

export default ResetPasswordComponent;
