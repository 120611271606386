import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "store/store";
import { useHistory } from "react-router-dom";
import { DirectionCheck } from "../../../../utils/classnameSwitch";
import TextField from "components/Inputs/TextField";
import {
  loginUser,
  userSelector,
  clearState,
  socialMediaLogin,
} from "store/slices/auth.slice";
import "../../Login.scss";
import AuthLayout from "components/Layout/AuthLayout/AuthLayout";
import SocialMediaWrapper from "components/SocialMediaWrapper/SocialMediaWrapper";
import { setToastState } from "components/Toast/Toast.slice";
import { couponsSelector } from "features/profile/components/coupons/coupons.slice";
import {
  stayDetailSelector,
  updateDates,
} from "features/stay-details/stay-details.slice";
import { guestCheckoutSelector } from "features/guest-checkout/guest-checkout.slice";
import { HTTPMSG } from "config";
import {
  checkoutSelector,
  getCheckoutDetails,
  getReservationId,
  clearState as clearCheckoutState,
} from "features/payment/checkout.slice";
import {
  searchSelector,
  updateSelection,
} from "features/search-property/searchproperty.slice";
import useLocalStorage from "hooks/useLocalStorage";
import { clearBookingStates } from "utils/utility";

type Inputs = {
  email: string;
  password: string;
};
export interface LoginComponentProps {
  onFormSubmit: (payload: Inputs) => void;
  currentPath: string;
}

const LoginComponent: React.FC<LoginComponentProps> = ({
  onFormSubmit,
  currentPath,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { isFetching, isSuccess, isError, errorMessage, userData } =
    useAppSelector(userSelector);
  const { fetchComplete } = useAppSelector(couponsSelector);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const { isError: checkoutErrorStatus, errorMessage: checkoutErrorMsg } =
    useAppSelector(guestCheckoutSelector);
  const { currentRoom, price, priceSelected, stayDetail } =
    useAppSelector(stayDetailSelector);
  const { checkin, checkout, guests } = useAppSelector(searchSelector);
  const { reservationid, checkoutdetails, checkoutErrors, fetchingReserID } =
    useAppSelector(checkoutSelector);

  const [resetPasswordFlow, setResetPasswordFlow] = useLocalStorage(
    "resetPasswordFlow",
    "false"
  );

  // eslint-disable-next-line
  const [registerFlow, setRegisterFlow] = useLocalStorage(
    "registerFlow",
    "false"
  );

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    dispatch(loginUser(data));
  };

  const saveDetails = () => {
    if (
      checkoutErrorStatus &&
      checkoutErrorMsg === HTTPMSG.USER_EXIST_CHECKOUT
    ) {
      setRegisterFlow("true");
      localStorage.setItem("checkin", JSON.stringify(checkin));
      localStorage.setItem("checkout", JSON.stringify(checkout));
      localStorage.setItem("currentRoom", currentRoom);
      localStorage.setItem("priceSelected", JSON.stringify(priceSelected));
      localStorage.setItem("price", JSON.stringify(price));
      localStorage.setItem("guests", JSON.stringify(guests));
      localStorage.setItem("stayDetail", JSON.stringify(stayDetail));
    }
  };

  const clearDates = () => {
    dispatch(
      updateSelection({
        category: "currentFrom",
        value: "",
        formattedD: "",
        master: "checkin",
      })
    );
    dispatch(
      updateSelection({
        category: "currentTo",
        value: "",
        formattedD: "",
        master: "checkout",
      })
    );
  };

  useEffect(() => {
    if (isError) {
      dispatch(
        setToastState({ type: "danger", message: errorMessage, dismiss: 3500 })
      );
      dispatch(clearState());
    }
    if (isSuccess) {
      dispatch(clearState());
      if (currentPath.includes("/stay-details")) {
        dispatch(updateDates(false));
      }
      if (currentPath.includes("stay-details")) {
        history.push(currentPath);
      } else if (
        userData?.role?.includes(2) &&
        userData?.is_email_verified &&
        !checkoutErrorStatus &&
        resetPasswordFlow === "false"
      ) {
        history.push("/owner");
      } else if (
        (checkoutErrorStatus &&
          checkoutErrorMsg === HTTPMSG.USER_EXIST_CHECKOUT) || // existing user prompted to login --> resume back to booking
        resetPasswordFlow === "true"
      ) {
        let roomInfo: any = {};
        roomInfo.from = checkin.formattedD;
        roomInfo.to = checkout.formattedD;
        roomInfo.room_category_id = currentRoom;
        roomInfo.rate_id =
          priceSelected.rate_id || price?.non_member?.non_refundable?.rate_id;
        roomInfo.guests = guests.currentGuests?.adult;
        dispatch(getReservationId(roomInfo));
      } else {
        if (userData?.role?.includes(2)) {
          dispatch(
            setToastState({
              type: "danger",
              message: "toast.verifyMail",
              dismiss: 3500,
            })
          );
        }
        history.push(currentPath);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isError,
    isSuccess,
    dispatch,
    history,
    currentPath,
    errorMessage,
    fetchComplete,
    userData,
  ]);

  /* Redirect user to checkout page for booking ----- begins */
  useEffect(() => {
    if (reservationid !== "") {
      const id = { reservation_id: reservationid };
      dispatch(getCheckoutDetails(id));
    }
    //eslint-disable-next-line
  }, [reservationid]);

  useEffect(() => {
    if (checkoutdetails.paymentIntent) {
      history.push("/book-room");
    }
    // eslint-disable-next-line
  }, [checkoutdetails]);

  useEffect(() => {
    if (checkoutErrors !== "") {
      dispatch(
        setToastState({
          type: "danger",
          message: HTTPMSG.BOOK_FAILED,
          dismiss: 5500,
        })
      );
      dispatch(clearCheckoutState());
    }
    //eslint-disable-next-line
  }, [dispatch, checkoutErrors]);
  /* Redirect user to checkout page for booking ----- ends */

  const [passwordShowIcon, setPasswordShowIcon] = useState(false);
  const togglePasswordVisiblity = (status: boolean) =>
    setPasswordShowIcon(status);

  const onSocialMediaLogin = (props: any) => {
    dispatch(socialMediaLogin(props));
  };

  const navigateToGuest = () => {
    dispatch(updateDates(false));
    // Storage Clean up
    if (resetPasswordFlow === "true" || registerFlow === "true") {
      clearDates();
      clearBookingStates();
    }
    history.push(currentPath || "/");
  };

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit(onSubmit)} noValidate dir={DirectionCheck()}>
        <TextField
          label={t("common.email")}
          placeholder={t("common.email")}
          register={register}
          name="email"
          type="email"
          errors={errors}
          rules={{
            required: true,
            pattern: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
          }}
        />

        <TextField
          label={t("common.pwd")}
          placeholder={t("common.pwd")}
          register={register}
          name="password"
          type={passwordShowIcon ? "text" : "password"}
          errors={errors}
          icon={passwordShowIcon ? "show" : "hide"}
          rules={{
            required: true,
          }}
          passwordVisibibility={togglePasswordVisiblity}
        />

        <div className="forget-pwd-link">
          <Link
            to="forgot-password"
            onClick={() => {
              setResetPasswordFlow("true");
              saveDetails();
            }}
          >
            {t("common.fgtpwd")}
          </Link>
        </div>

        <div className="field is-grouped">
          <div className="control">
            <button
              className={`button is-link login-btn ${
                isFetching || fetchingReserID ? "is-loading" : ""
              }`}
              type="submit"
            >
              {t("common.login")}
            </button>
          </div>
        </div>
      </form>
      <SocialMediaWrapper
        onSocialMediaBtnClick={onSocialMediaLogin}
        dir={DirectionCheck()}
      />

      <div className="guest-link p-0" dir={DirectionCheck()}>
        <button className="continue-as-guest" onClick={navigateToGuest}>
          {t("login.continue")}
        </button>
      </div>

      <div className="register-link" dir={DirectionCheck()}>
        {t("login.noAccnt")}{" "}
        <span>
          <Link
            to={{
              pathname: `/register`,
              state: {
                prevPath: currentPath,
              },
            }}
            onClick={() => {
              saveDetails();
            }}
          >
            {t("login.reg")}
          </Link>
        </span>
      </div>
    </AuthLayout>
  );
};

export default LoginComponent;
