import React, { useState, useEffect } from "react";
import StaycaeScheduler from "./components/scheduler/StaycaeScheduler";
import ToggleSwitch from "./components/toggle/ToggleSwitch";
import Legend from "./components/legend/Legend";
import "./Reservation.scss";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerOwner from "./components/datepicker/DatePicker";
import ReservationTable from "./components/reservationtable/ReservationTable";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  clearFilters,
  getReservationList,
  getReservations,
} from "./reservation.slice";
import MultiSelect from "components/multi-select/MultiSelect";
import ReservationDD from "./components/reservationdropdown/ReservationDD";
import { multiSelectSelector } from "components/multi-select/multi-select.slice";
import TableSearch from "./components/tablesearch/TableSearch";
import { ownerSelector } from "../owner.slice";
import NoUnits from "../no-units/NoUnits";
import { useLocation } from "react-router-dom";
import en from "locale/en";
import noResults from "assets/images/coupons/no-results.svg";
import MapLoader from "features/search-result/components/MapLoader/MapLoader";

const Reservation: React.FC<any> = () => {
  const location: any = useLocation();
  const [tableView, setTableView] = useState(false);
  const dispatch = useAppDispatch();
  const { selecteddate, data, loading, status, searchedQuery, selectedstatus } =
    useAppSelector(getReservations);
  const [show, setCheckin] = useState(false);
  const { options } = useAppSelector(multiSelectSelector);
  const toggleSwitchSelect = (checked: any) => {
    if (checked.selected === "table") {
      setTableView(true);
    } else {
      setTableView(false);
    }
  };

  useEffect(() => {
    if (location.state) {
      setTableView(true);
    }
  }, [location]);

  const renderStatus = (type: any) => {
    const tagStyle =
      type === "Pencil"
        ? "yellow"
        : type === "Confirmed"
        ? "green"
        : type === "Cancelled"
        ? "red"
        : type === "Arrived"
        ? "dark-green"
        : "paleYellow";
    return <span className={`tag ${tagStyle}`}>{type}</span>;
  };
  const columns = [
    {
      Header: "Reservation #",
      accessor: "reservation_id", // accessor is the "key" in the data
    },
    {
      Header: "Room #",
      accessor: "room.name",
    },
    {
      Header: "Booking date",
      accessor: "booking_date",
      Cell: (props: any) => (
        <span>
          {props.value ? moment(props.value).format("YYYY-MM-DD") : "-"}
        </span>
      ),
    },
    {
      Header: "Arrival date",
      accessor: "arrival_date",
      Cell: (props: any) => (
        <span>
          {props.value ? moment(props.value).format("YYYY-MM-DD") : "-"}
        </span>
      ),
    },
    {
      Header: "Departure date",
      accessor: "departure_date",
      Cell: (props: any) => (
        <span>
          {props.value ? moment(props.value).format("YYYY-MM-DD") : "-"}
        </span>
      ),
    },
    {
      Header: "# of nights",
      accessor: "length_of_stay",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (props: any) => <span>{renderStatus(props.value)}</span>,
    },
    {
      Header: "Revenue",
      accessor: "revenue",
    },
    {
      Header: "Tax/Other",
      accessor: "tax_other",
    },
    {
      Header: "Total",
      accessor: "amount",
    },
  ];

  const toggle = () => {
    setCheckin(!show);
  };

  const [searching, setSearching] = useState(true);

  useEffect(() => {
    // Get reservation list only if units are selected and search button is pressed
    if (options.length !== 0 && selecteddate.to !== "" && searching) {
      dispatch(
        getReservationList({
          ...selecteddate,
          units: options.map((unit) => unit.id).join(),
          limit: 1000,
          reservation_id: searchedQuery || "",
          status: selectedstatus || "",
          page: 2,
        })
      );
      setSearching(false);
    }
  }, [
    options,
    selecteddate,
    dispatch,
    searching,
    searchedQuery,
    selectedstatus,
  ]);

  useEffect(() => {
    return () => {
      // clear reservation filter on leaving the page
      dispatch(clearFilters());
    };
    // eslint-disable-next-line
  }, []);

  const { data: units, isFetching, isSuccess } = useAppSelector(ownerSelector);
  if (!isFetching && isSuccess && units.length === 0 && options.length === 0) {
    return <NoUnits />;
  }

  return (
    <>
      <div className="dashboard-main owner-reservation-main mb-4">
        <div className="owner-scheduler-header">
          <DatePickerOwner></DatePickerOwner>

          <div
            className={`owner-reservation-units ${tableView ? "show" : "hide"}`}
          >
            <div className="mr-3">Units </div>{" "}
            <MultiSelect placeholder="Select Units" />
          </div>

          {tableView && (
            <>
              <div>
                <ReservationDD
                  name="CHECKOUT"
                  title="To"
                  onClick={toggle}
                ></ReservationDD>
              </div>
            </>
          )}

          <div className="toggle-container">
            {!tableView && <Legend></Legend>}
            <ToggleSwitch
              onSelect={toggleSwitchSelect}
              selectedValue={tableView}
            ></ToggleSwitch>
          </div>
        </div>

        <div>
          {tableView && (
            <TableSearch
              onSearch={(status: boolean) => setSearching(status)}
            ></TableSearch>
          )}
        </div>

        <div className="scheduler-wrap" style={{ overflow: "hidden" }}>
          {!tableView && loading ? (
            <MapLoader />
          ) : !data?.reservations.length &&
            status === "fulfilled" &&
            !tableView ? (
            <div
              className="coupons-wrapper no-result-coupon"
              style={{ margin: "0 auto", backgroundColor: "white" }}
            >
              <img src={noResults} alt="No reservations found" />
              <h3>{en.noReservation}</h3>
              <p>{en.noReservationDesc}</p>
            </div>
          ) : null}

          {!tableView && data?.reservations.length ? (
            <StaycaeScheduler {...data} loading={loading}></StaycaeScheduler>
          ) : null}

          {tableView && data?.reservations && (
            <ReservationTable
              config={columns}
              tableData={data?.reservations}
              loading={loading}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Reservation;
